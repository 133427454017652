import { BrowserRouter, Route, Routes } from "react-router-dom";

import './app.scss';
import Valory from "./pages/valory-tr";

export default function App() {
   return (
       <>
           <BrowserRouter>
               <Routes>
                   <Route path="/" element={<Valory />}></Route>
               </Routes>
           </BrowserRouter>
       </>
   )
}
