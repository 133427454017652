import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import { Button, Modal } from 'antd';
// import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import { validatePhoneNumber, validateLandlinePhoneNumber } from "../../common/PhoneNumberValidation";
import { WEBSITE_EXTERNAL_LAMBDA_ENDPOINT, WEBSITE_X_API_KEY, ORGZIT_CALL_PERMISSION, CMS_API_ENDPOINT, INTERNAL_CRM_CALL_PERMISSION } from "../../common/constants";

const FooterFormSection = () => {
    const simpleValidator = useRef(new SimpleReactValidator());
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const [landingFormData, setLandingFormData] = useState({
        firstName: "",
        email: "",
        source: "Landing Page - Valory",
        phone: "",
        dialCode: "+90",
        company: "",
        PhoneCallCheck: false,
        smsCheck: false,
        emailCheck: false,
        terms: false
    })
    const [phoneWarn, setPhoneWarn] = useState('');
    const [firstNameWarn, setFirstNameWarn] = useState('');
    const [emailWarn, setEmailWarn] = useState('');
    const [companyWarn, setCompanyWarn] = useState('');
    const [termsWarn, setTermsWarn] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const checkboxTermsRef = useRef(null);
    const checkboxPhoneRef = useRef(null);
    const checkboxSmsRef = useRef(null);
    const checkboxEmailRef = useRef(null);
    const [dialsWarn, setDialWarn] = useState('');
    const [countries, setCountries] = useState([]);
    const [dialCodeWarn, setDialCodeWarn] = useState('');
    const { PhoneCallCheck, smsCheck, emailCheck } = landingFormData;
    const checkedCheckboxes = [PhoneCallCheck, smsCheck, emailCheck].filter((checkbox) => checkbox === true);

    async function fetchCountries() {
        try {
            const res = await axios.get("/assets/data/countryCode.json");
            if (res) {
                setCountries(res.data);
            }
        } catch (error) {
            console.error("error ", error);
        }
    }

    const resetStateValues = () => {
        setPhoneWarn("");
        setFirstNameWarn("");
        setEmailWarn("");
        setCompanyWarn("");
        setTermsWarn("");
        setDialWarn("");
        checkboxTermsRef.current.checked = false;
        checkboxPhoneRef.current.checked = false;
        checkboxSmsRef.current.checked = false;
        checkboxEmailRef.current.checked = false;
        setLandingFormData({
            firstName: "",
            email: "",
            phone: "",
            source: "Landing Page - Valory Güneşlİ MERKEZEFENDI",
            dialCode: "+90",
            company: "",
            address: "",
            terms: false,
            PhoneCallCheck: false,
            smsCheck: false,
            emailCheck: false,
        })
    }

    const createLeadOnOrgzit = async () => {
        if (ORGZIT_CALL_PERMISSION) {
            const orgzit = await axios.post(WEBSITE_EXTERNAL_LAMBDA_ENDPOINT +
                "become-our-partner/landing-enrollment-request", landingFormData
            )
            return orgzit;
        }
    };

    const createLead = async (postBody) => {
        if (INTERNAL_CRM_CALL_PERMISSION) {
            const lead = await axios
                .post(CMS_API_ENDPOINT + "crm/lead/add", postBody, { headers: { 'x-api-key': WEBSITE_X_API_KEY } })
            return lead;
        }
    };

    const handleChangeTerms = (status) => {
        setLandingFormData(landingFormData => ({ ...landingFormData, terms: status }));
    }
    const handleChangePhoneCheck = (status) => {
        setLandingFormData(landingFormData => ({ ...landingFormData, PhoneCallCheck: status }));
    }
    const handleChangeSmsCheck = (status) => {
        setLandingFormData(landingFormData => ({ ...landingFormData, smsCheck: status }));
    }
    const handleChangeEmailCheck = (status) => {
        setLandingFormData(landingFormData => ({ ...landingFormData, emailCheck: status }));
    }

    const onSuccessForm = () => {
        resetStateValues();
        Modal.success({
            content: (
                <div>
                    <p>Teşekkürler!</p>
                    <p>Yakında sizinle iletişime geçeceğiz</p>
                </div>
            ),
            okText: "Tamam"
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if ((landingFormData.firstName.length == "" || landingFormData.firstName.trim() === "" ||
        landingFormData.email.length == "" || landingFormData.email.trim() === "" ||
        landingFormData.phone.length == "" || landingFormData.company.length == "" ) || 
        simpleValidator.current.errorMessages.email || dialCodeWarn){
            setFirstNameWarn("Lütfen Adınızı Yazın")
            setPhoneWarn("Lütfen Geçerli Bir Telefon Numarası Yazın")
            setEmailWarn("Lütfen Geçerli Bir E-Mail Adresi Yazın")
            setCompanyWarn("Lütfen Firma Adı Yazın")
            return false;
        }
        if (landingFormData.terms === false) {
            setTermsWarn("lütfen şartları kabul edin")
            return false;
        }
        const { PhoneCallCheck, smsCheck, emailCheck } = landingFormData;
        const checkedCheckboxes = [PhoneCallCheck, smsCheck, emailCheck].filter((checkbox) => checkbox === true);
        if (checkedCheckboxes.length < 1) {
            // Show an error message if less than 2 checkboxes are checked
            setDialWarn("Bir iletişim yöntemi seçmek zorundasınız.");
            return false;
        }
        setBtnLoading(true);
        const postDataValue = {
            name: landingFormData.firstName.trim(),
            email: landingFormData.email.trim(),
            phoneNumber: landingFormData.dialCode + landingFormData.phone.trim(),
            source: "Landing Page - Valory Gunesli Residans",
            fromWebsite: true,
        };

        const [orgzitCall, internalCRM] = await Promise.all([
            createLeadOnOrgzit(),
            createLead(postDataValue)
        ]);

        if (orgzitCall?.data?.status === "Success") {
            onSuccessForm();
        } else if (internalCRM?.data?.status === "Success") {
            onSuccessForm();
        } else {
            console.error("Error while form submitting");
        }
        setBtnLoading(false);
    }

    const checkTheLandlineAndPhoneNumber = (PhoneNumber, dialCode) => {
        let validationData = PhoneNumber && dialCode ? `${dialCode}${PhoneNumber}` : "";
        const parsedNumber = phoneNumberUtil.parse(validationData);
        const isoCode = parsedNumber ? phoneNumberUtil.getRegionCodeForNumber(parsedNumber) : "";
        if (validationData && isoCode) {
            if (validateLandlinePhoneNumber(validationData, isoCode, phoneNumberUtil, PhoneNumberType) ||
                validatePhoneNumber(validationData, isoCode, phoneNumberUtil)) {
                setDialCodeWarn("")
            } else {
                setDialCodeWarn("Lütfen Geçerli Bir Telefon Numarası Yazın");
            }
        }
    }

    const handleSelectChange = (event) => {
        setLandingFormData({
            ...landingFormData,
            dial_code: event.target.value
        })
        checkTheLandlineAndPhoneNumber(landingFormData?.phone, event.target.value)
    };


    useEffect(() => {
        fetchCountries();
    }, [])
    return (
        <>
            <div className="footer_form_section">
                <div className="footer_form_box">
                    <div className="row justify-content-center p-0 m-0">
                        <div className="col-md-9">
                            <div className="row justify-content-between">
                                <div className="col-md-6">
                                    <div className="contact_form_detail">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    placeholder="İsminiz"
                                                    className="form-control"
                                                    onChange={(e) => setLandingFormData({
                                                        ...landingFormData,
                                                        firstName: e.target.value
                                                    })}
                                                    value={landingFormData.firstName}
                                                />
                                                <div className='validationError'>
                                                <p className='errorMsg'>{landingFormData?.firstName.length == "" ? firstNameWarn : ""}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 ">
                                                <input
                                                    type="text"
                                                    placeholder="Firma Adı"
                                                    className="form-control"
                                                    onChange={(e) => setLandingFormData({
                                                        ...landingFormData,
                                                        company: e.target.value
                                                    })}
                                                    value={landingFormData.company}
                                                />
                                                <div className='validationError'>
                                                <p className='errorMsg'>{ landingFormData.company.length == "" ? companyWarn : ""}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 ">
                                                <div className="row align-items-center">
                                                    <div className="col-5">
                                                        <select className="form-control" onChange={handleSelectChange}>
                                                            {countries.length > 0 && countries.map((row, index) =>
                                                                <option value={row.dial_code} key={index}>{row.name}{row.dial_code}</option>)}
                                                        </select>
                                                    </div>
                                                    <div className="col-7">
                                                        <input
                                                            type="tel"
                                                            id="txtPhone"
                                                            className="txtbox form-control"
                                                            onChange={(e) => {
                                                                setLandingFormData({
                                                                    ...landingFormData, phone: e.target.value.replace(/\D/g, "")
                                                                })
                                                                checkTheLandlineAndPhoneNumber(e.target.value.replace(/\D/g, ""), landingFormData.dialCode)
                                                            }}
                                                            placeholder="Telefon"
                                                            value={landingFormData.phone}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='validationError'>
                                                <p className='errorMsg'>{landingFormData?.phone?.length == "" ? phoneWarn : dialCodeWarn ? dialCodeWarn : ""}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 ">
                                                <input
                                                    type="text"
                                                    placeholder="E-Posta Adresiniz"
                                                    className="form-control"
                                                    onKeyUp={() => simpleValidator?.current?.showMessageFor("email")}
                                                    onChange={(e) => setLandingFormData({
                                                        ...landingFormData,
                                                        email: e.target.value
                                                    })}
                                                    value={landingFormData.email}
                                                />
                                                <div className='validationError'>
                                                <p className='errorMsg'>{landingFormData?.email?.length == "" || landingFormData.email.trim() === "" ? emailWarn : ""}</p>
                                                      <p className='errorMsg'>{simpleValidator?.current?.message('email', landingFormData?.email, 'email') ? "Lütfen Geçerli Bir E-Mail Adresi Yazın" : ""}</p>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="terms_coldition">
                                                            <input type="checkbox"
                                                                onClick={() => handleChangeTerms(!landingFormData.terms)}
                                                                value={landingFormData.terms}
                                                                ref={checkboxTermsRef} />
                                                            <a href="https://www.cubedots.com/terms_and_conditions" target="_blank"><h6>Aydınlatma Metni'ni okudum, anladım.</h6></a>
                                                            <div className='validationError'>
                                                                <p className='errorMsg'>{landingFormData.terms === false ? termsWarn : ""}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="contact_box">
                                                            <div className="phone_sms">
                                                                <input type="checkbox" onClick={() => handleChangePhoneCheck(!landingFormData.PhoneCallCheck)}
                                                                    value={landingFormData.PhoneCallCheck}
                                                                    ref={checkboxPhoneRef} />
                                                                <h6 style={{ color: "#024059" }}>Telefon Araması</h6>
                                                            </div>
                                                            <div className="phone_sms">
                                                                <input type="checkbox" onClick={() => handleChangeSmsCheck(!landingFormData.smsCheck)}
                                                                    value={landingFormData.smsCheck}
                                                                    ref={checkboxSmsRef} />
                                                                <h6 style={{ color: "#024059" }}>SMS Gönderimi/Whatsapp</h6>
                                                            </div>
                                                            <div className="phone_sms">
                                                                <input type="checkbox" onClick={() => handleChangeEmailCheck(!landingFormData.emailCheck)}
                                                                    value={landingFormData.emailCheck}
                                                                    ref={checkboxEmailRef} />
                                                                <h6 style={{ color: "#024059" }}>E-posta Gönderimi</h6>
                                                            </div>
                                                        </div>
                                                        <p className="check_box_warn_footer">{checkedCheckboxes.length < 1 ? dialsWarn : ""}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 text-md-center text-md-end text-center">
                                                <Button loading={btnLoading} className="submit_btn" id="btnSubmit" onClick={onSubmit}>Gönder</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="alya_contact_detail">
                                        <div className="row">
                                            <div className="col-md-8">
                                                {/* <h3>İLETİŞİM</h3> */}
                                                <img src="/assets/images/valory/footer_logo.png" className="img=fluid" style={{ width: "50%" }} />
                                                <div className="address_content">
                                                    {/* <h5>ADRES</h5> */}
                                                    <p style={{ color: "#024059" }}> Bağlar, Osman Paşa </p>
                                                    <p style={{ color: "#024059" }}> Cd. No:97/1, 34209 Bağcılar/İstanbul.</p>
                                                </div>
                                                <div className="address_content">
                                                    <h5 >TELEFON</h5>
                                                    <p style={{ color: "#024059" }}>0212 963 0062</p>
                                                </div>
                                                <div className="address_content">
                                                    <h5>E-POSTA</h5>
                                                    <p style={{ color: "#024059" }}>info@cubedots.com</p>
                                                </div>
                                                {/* <h6 style={{ color: "#024059" }}>BİZİ TAKİP EDİN</h6> */}
                                                {/* <div className="contact_social_icons">
                                                    <a target="_blank" href=" https://www.instagram.com/valory.gunesli/"> <img style={{ marginLeft: "20px" }} src="/assets/images/valory/insta.svg" /></a>
                                                </div> */}
                                            </div>
                                            <div className="col-md-4">
                                                <div className="bar_code_image">
                                                    {/* <img src="/assets/images/block/barcode.svg" /> */}
                                                    <a style={{ color: "#A90505" }} target="_blank" href="https://goo.gl/maps/AGJsLJEZPLnkP1VP7">HARİTADA GÖSTER</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FooterFormSection;