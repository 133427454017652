import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Modal, Button } from "react-bootstrap";

const AlyaFloorplanDetail = () => {
    const [floorPlanImage, setFloorPlanImage] = useState({});
    const [selectedOption, setSelectedOption] = useState("2+1");
    const [selectedImage, setSelectedImage] = useState("");
    const [activeTab, setActiveTab] = useState("floorplans");
    const [showModal, setShowModal] = useState(false);
    const [selectedImageInterior, setselectedImageInterior] = useState("");

    const handleOpenModal = (image) => {
        setselectedImageInterior(image);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const interiorImage = [
        "./assets/images/valory/interior/01.jpg",
        "./assets/images/valory/interior/02.jpg",
        "./assets/images/valory/interior/03.jpg",
        "./assets/images/valory/interior/04.jpg"
    ]

    const data = "./assets/data/valoryFloorplan.json";

    const fetchData = () => {
        fetch(data)
            .then((response) => response.json())
            .then((data) => setFloorPlanImage(data))
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        fetchData();
    }, []);
    if (!Object.keys(floorPlanImage).length) {
        return null; // or a loading indicator
    }
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <div className="container-fluid floorplan_section">
                <div className="row justify-content-center">
                    <div className="col-md-9">
                        <div className="floor_detail">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${activeTab === "floorplans" ? "active" : ""}`}
                                        id="home-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#home-tab-pane"
                                        type="button"
                                        role="tab"
                                        aria-controls="home-tab-pane"
                                        aria-selected={activeTab === "floorplans" ? "true" : "false"}
                                        style={{ color: "#024059", fontWeight: "700" }}
                                        onClick={() => handleTabChange("floorplans")}
                                    >
                                        Daire planları
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${activeTab === "interior" ? "active" : ""}`}
                                        id="profile-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#profile-tab-pane"
                                        type="button"
                                        role="tab"
                                        aria-controls="profile-tab-pane"
                                        aria-selected={activeTab === "interior" ? "true" : "false"}
                                        style={{ color: "#02405980", fontWeight: "700" }}
                                        onClick={() => handleTabChange("interior")}
                                    >
                                        İç görseller
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className={`tab-pane fade ${activeTab === "floorplans" ? "show active" : ""}`} id="home-tab-pane" role="tabpanel" style={{ textAlign: "end" }} aria-labelledby="home-tab" tabIndex="0">
                                    <select
                                        style={{ width: "13%", border: "none", padding: " 5px" }}
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                    >
                                        <option value="1+1">1+1</option>
                                        <option value="2+1">2+1</option>
                                        <option value="3+1">3+1</option>
                                        <option value="4+1">4+1</option>
                                    </select>
                                    {floorPlanImage[selectedOption]?.map((item, index) => (
                                        <div className="row" key={index} style={{ minHeight: '680px' }}>
                                            <Carousel>
                                                {item.image_path.map((image, imageIndex) => (
                                                    imageIndex % 4 === 0 && (
                                                        <Carousel.Item key={imageIndex}>
                                                            <div className="row">
                                                                {item.image_path
                                                                    .slice(imageIndex, imageIndex + 4)
                                                                    .map((image, subIndex) => (
                                                                        <div className="col-md-6" key={subIndex}>
                                                                            <div className="image-container">
                                                                                <img
                                                                                    src={image}
                                                                                    alt={`Floor Plan ${index}-${imageIndex}`}
                                                                                    className="img-fluid"
                                                                                />
                                                                                <div className="image_section_overlay" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handleImageClick(image)}>
                                                                                    <img src="./assets/images/valory/eye.svg" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </Carousel.Item>
                                                    )
                                                ))}
                                            </Carousel>
                                        </div>
                                    ))}
                                </div>
                                <div className={`tab-pane fade ${activeTab === "interior" ? "show active" : ""}`} id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                    <div className="row">
                                        {interiorImage.map((image, index) => (
                                            <div className="col-md-6" key={index}>
                                                <div className="image-container">
                                                    <img
                                                        src={image}
                                                        className="img-fluid"
                                                        alt={`Interior ${index + 1}`}

                                                    />
                                                    <div className="image_section_overlay" onClick={() => handleOpenModal(image)}>
                                                        <img src="./assets/images/valory/eye.svg" alt="Eye Icon" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <img
                                src={selectedImage}
                                alt="Selected Floor Plan"
                                className="modal-img img-fluid"
                            />
                        </div>
                        <div className="modal-footer"></div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body closeButton>
                    <img src={selectedImageInterior} className="img-fluid" alt="Selected Interior" />
                </Modal.Body>
            </Modal >
            <div className="location_box">
                <div className="map_images">
                    <img
                        style={{ backgroundColor: "#D9D9D933" }}
                        src="/assets/images/valory/map.png"
                        className="img-fluid"
                        alt="Map"
                    />
                </div>
            </div>
        </>
    );
};

export default AlyaFloorplanDetail;
