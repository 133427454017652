import React from "react";

const BlockDetail = () => {
    return (
        <>
            <div className="block_detail">
                <div className="block_content">
                    <img src="/assets/images/valory/block/1.svg" />
                    <h5 style={{ color: "#467B7C" }}>Blok</h5>
                    <h6 style={{ color: "#467B7C" }}>2</h6>
                </div>
                <div className="block_content">
                    <img src="/assets/images/valory/block/2.svg" />
                    <h5 style={{ color: "#467B7C" }}>Daire</h5>
                    <h6 style={{ color: "#467B7C" }}>216</h6>
                </div>
                <div className="block_content">
                    <img src="/assets/images/valory/block/3.svg" />
                    <h5 style={{ color: "#467B7C" }}>Ticari Ünite</h5>
                    <h6 style={{ color: "#467B7C" }}>12</h6>
                </div>
                <div className="block_content">
                    <img src="/assets/images/valory/block/4.svg" />
                    <h5 style={{ color: "#467B7C" }}>Daire Tipleri</h5>
                    <h6 style={{ color: "#467B7C" }}>1+1,2+1,3+1, <br />
                        4+1</h6>
                </div>
                <div className="block_content">
                    <img src="/assets/images/valory/block/5.svg" />
                    <h5 style={{ color: "#467B7C" }}>Daire Büyüklükler</h5>
                    <h6 style={{ color: "#467B7C" }}>64.76 m2 - 309.01 m2</h6>
                </div>
                <div className="block_content">
                    <img src="/assets/images/valory/block/6.svg" />
                    <h5 style={{ color: "#467B7C" }}>Arsa Alanı</h5>
                    <h6 style={{ color: "#467B7C" }}>8621.12 m2</h6>
                </div>
                <div className="block_content">
                    <img src="/assets/images/valory/block/7.svg" />
                    <h5 style={{ color: "#467B7C" }}>İnşaat Alanı</h5>
                    <h6 style={{ color: "#467B7C" }}>43975,70 m2</h6>
                </div>
            </div>
        </>
    )
}
export default BlockDetail;