import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from 'simple-react-validator';
import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import axios from "axios";
import { Button, Modal } from 'antd';
// import $ from 'jquery';
import { validatePhoneNumber, validateLandlinePhoneNumber } from "../common/PhoneNumberValidation";
import { WEBSITE_EXTERNAL_LAMBDA_ENDPOINT, WEBSITE_X_API_KEY, ORGZIT_CALL_PERMISSION, CMS_API_ENDPOINT, INTERNAL_CRM_CALL_PERMISSION } from "../common/constants";
import ValoryDetail from "../components/valory/ValoryDetail";
import FooterCarousel from "../components/valory/FooterCarousel";
import ValoryFloorplanDetail from "../components/valory/ValoryFloorplanDetail";
import FooterFormSection from "../components/valory/FooterFormSection";


const Valory = () => {
    const simpleValidator = useRef(new SimpleReactValidator());
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const [landingFormData, setLandingFormData] = useState({
        firstName: "",
        email: "",
        source: "Landing Page - Valory",
        phone: "",
        dialCode: "+90",
        company: "",
        PhoneCallCheck: false,
        smsCheck: false,
        emailCheck: false,
        terms: false
    })
    const [phoneWarn, setPhoneWarn] = useState('');
    const [firstNameWarn, setFirstNameWarn] = useState('');
    const [emailWarn, setEmailWarn] = useState('');
    const [companyWarn, setCompanyWarn] = useState('');
    const [termsWarn, setTermsWarn] = useState('');
    const [dialsWarn, setDialWarn] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const checkboxTermsRef = useRef(null);
    const checkboxPhoneRef = useRef(null);
    const checkboxSmsRef = useRef(null);
    const checkboxEmailRef = useRef(null);
    const [countries, setCountries] = useState([]);
    const [dialCodeWarn, setDialCodeWarn] = useState('');
    const { PhoneCallCheck, smsCheck, emailCheck } = landingFormData;
    const checkedCheckboxes = [PhoneCallCheck, smsCheck, emailCheck].filter((checkbox) => checkbox === true);
    async function fetchCountries() {
        try {
            const res = await axios.get("/assets/data/countryCode.json");
            if (res) {
                // console.log("res.data ", res.data);
                setCountries(res.data);
            }
        } catch (error) {
            console.error("error ", error);
        }
    }

    const resetStateValues = () => {
        setPhoneWarn("");
        setFirstNameWarn("");
        setEmailWarn("");
        setCompanyWarn("");
        setTermsWarn("");
        setDialWarn("");
        checkboxTermsRef.current.checked = false;
        checkboxPhoneRef.current.checked = false;
        checkboxSmsRef.current.checked = false;
        checkboxEmailRef.current.checked = false;
        setLandingFormData({
            firstName: "",
            email: "",
            phone: "",
            source: "Landing Page - Valory",
            dialCode: "+90",
            company: "",
            terms: false,
            PhoneCallCheck: false,
            smsCheck: false,
            emailCheck: false,
        })
    }

    const handleChangeTerms = (status) => {
        setLandingFormData(landingFormData => ({ ...landingFormData, terms: status }));
    }
    const handleChangePhoneCheck = (status) => {
        setLandingFormData(landingFormData => ({ ...landingFormData, PhoneCallCheck: status }));
    }
    const handleChangeSmsCheck = (status) => {
        setLandingFormData(landingFormData => ({ ...landingFormData, smsCheck: status }));
    }
    const handleChangeEmailCheck = (status) => {
        setLandingFormData(landingFormData => ({ ...landingFormData, emailCheck: status }));
    }


    const createLeadOnOrgzit = async () => {
        if (ORGZIT_CALL_PERMISSION) {
            const orgzit = await axios.post(WEBSITE_EXTERNAL_LAMBDA_ENDPOINT +
                "become-our-partner/landing-enrollment-request", landingFormData
            )
            return orgzit;
        }
    };

    const createLead = async (postBody) => {
        if (INTERNAL_CRM_CALL_PERMISSION) {
            const lead = await axios
                .post(CMS_API_ENDPOINT + "crm/lead/add", postBody, { headers: { 'x-api-key': WEBSITE_X_API_KEY } })
            return lead;
        }
    };

    const onSuccessForm = () => {
        resetStateValues();
        Modal.success({
            content: (
                <div>
                    <p>Teşekkürler!</p>
                    <p>Yakında sizinle iletişime geçeceğiz</p>
                </div>
            ),
            okText: "Tamam"
        });
    }

    const onSubmit = async (e) => {
        setBtnLoading(true);
        e.preventDefault();
        if (landingFormData.firstName.length === "" || landingFormData.company.length === "" || landingFormData.email.length === "" || landingFormData.phone.length < 4) {
            setFirstNameWarn("Lütfen Adınızı Yazın")
            setPhoneWarn("Lütfen Geçerli Bir Telefon Numarası Yazın")
            setEmailWarn("Lütfen Geçerli Bir E-Mail Adresi Yazın")
            setCompanyWarn("")
            setBtnLoading(false);
            return false;
        }
        if (landingFormData.terms === false) {
            setTermsWarn("lütfen şartları kabul edin")
            setBtnLoading(false);
            return false;
        }
        const { PhoneCallCheck, smsCheck, emailCheck } = landingFormData;
        const checkedCheckboxes = [PhoneCallCheck, smsCheck, emailCheck].filter((checkbox) => checkbox === true);
        if (checkedCheckboxes.length < 1) {
            setDialWarn("Bir iletişim yöntemi seçmek zorundasınız.");
            setBtnLoading(false);
            return false;
        }

        const postDataValue = {
            name: landingFormData.firstName.trim(),
            email: landingFormData.email.trim(),
            phoneNumber: landingFormData.dialCode + landingFormData.phone.trim(),
            source: "Landing Page - Valory Gunesli Residans",
            fromWebsite: true,
        };

        const [orgzitCall, internalCRM] = await Promise.all([
            createLeadOnOrgzit(),
            createLead(postDataValue)
        ]);

        if (orgzitCall?.data?.status === "Success") {
            onSuccessForm();
        } else if (internalCRM?.data?.status === "Success") {
            onSuccessForm();
        } else {
            console.error("Error while form submitting");
        }
        setBtnLoading(false);
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        fetchCountries();
    }, []);

    const handleInstagram = () => {
        window.open("https://instagram.com/cubedots?igshid=NDk5N2NlZjQ=")
    }

    const checkTheLandlineAndPhoneNumber = (PhoneNumber, dialCode) => {
        let validationData = PhoneNumber && dialCode ? `${dialCode}${PhoneNumber}` : "";
        const parsedNumber = phoneNumberUtil.parse(validationData);
        const isoCode = parsedNumber ? phoneNumberUtil.getRegionCodeForNumber(parsedNumber) : "";
        if (validationData && isoCode) {
            if (validateLandlinePhoneNumber(validationData, isoCode, phoneNumberUtil, PhoneNumberType) ||
                validatePhoneNumber(validationData, isoCode, phoneNumberUtil)) {
                setDialCodeWarn("")
            } else {
                setDialCodeWarn("Lütfen Geçerli Bir Telefon Numarası Yazın");
            }
        }
    }

    const handleSelectChange = (event) => {
        setLandingFormData({
            ...landingFormData,
            dial_code: event.target.value
        })
        checkTheLandlineAndPhoneNumber(landingFormData?.phone, event.target.value)
    };

    return (
        <>
            <div className="alyaSection">
                <section className="tema_istanbul_main_section" id="desktop-nav">
                    <div className="yigi_logo">
                        <a href="https://www.yigitoglu.com.tr/"><img src="/assets/images/valory/isolation.png" className="img-fluid isolation" alt="" /></a>
                    </div>
                    <article className="main_banner_section" id="01">
                        <div className="main_banner_carousel">
                            <div id="carouselExampleIndicatorsMain" className="carousel slide d-md-block d-none" data-bs-ride="true">
                                <div className="carousel-indicators">
                                    <button type="button" id="btn1" data-bs-target="#carouselExampleIndicatorsMain"
                                        data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" id="btn2" data-bs-target="#carouselExampleIndicatorsMain"
                                        data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" id="btn3" data-bs-target="#carouselExampleIndicatorsMain"
                                        data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img className="img-fluid" src="/assets/images/valory/1.png" alt="konaklari" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/valory/2.png" alt="konaklari" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/valory/3.png" alt="konaklari" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main_banner_image_section">
                            <div className="container">
                                <div className="position-relative">
                                    {/* <div className="social_icons">
                                        <p className="instagram" onClick={() => handleInstagram()}>Bizi takip edin</p>
                                        <div className="social_icons_image">
                                            <div>
                                                <a href=" https://www.instagram.com/valory.gunesli/" target="_blank" rel="noreferrer">
                                                    <img src="/assets/images/valory/instagram.png" alt="instagram" />
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row m-0 justify-content-center">
                                        <div className="col-lg-8 col-md-9">
                                            <div className="main_banner_content">
                                                <div className="text-center">
                                                    <img src="/assets/images/valory/valory_logo.png" className="img-fluid" alt="" />
                                                </div>
                                                <div className="main_banner_content_box_section">
                                                    <small className="small_text">Valory Güneşlİ</small>
                                                    <h1>Valory Güneşli İle Modern Yaşamın Güzelliklerini Keşfedin</h1>
                                                    <p>
                                                        İstanbul'un Basın Ekspres bölgesinin kalbinde konumlanan Valory Güneşli, sakinlerine modern daireler sunmanın yanı sıra şehirdeki alışveriş, yeme-içme ve eğlence gibi birçok seçeneğe de kolay ulaşım imkânı sağlar. Ayrıca dairelerimiz, konfor ve kullanışlılık amacı doğrultusunda tasarlanmış olup, metropol sakinleri için mükemmel bir seçenek olarak karşımıza çıkmaktadır.
                                                    </p>
                                                </div>
                                                <div className="form_box">
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-4 name_col">
                                                            <input
                                                                type="text"
                                                                placeholder="İsminiz"
                                                                className="form-control"
                                                                onChange={(e) => setLandingFormData({
                                                                    ...landingFormData,
                                                                    firstName: e.target.value
                                                                })}
                                                                value={landingFormData.firstName}
                                                            />
                                                            <div className='validationError'>
                                                            <p className='errorMsg'>{landingFormData?.firstName.length == ""  ? firstNameWarn : ""}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 code_col">
                                                            <div className="row align-items-center">
                                                                <div className="col-5 pe-1">
                                                                    <select className="form-control" onChange={handleSelectChange}>
                                                                        {countries.length > 0 && countries.map((row, index) =>
                                                                            <option value={row.dial_code} key={index}>{row.name}{row.dial_code}</option>)}
                                                                    </select>
                                                                </div>
                                                                <div className="col-7 ps-1">
                                                                    <input
                                                                        type="tel"
                                                                        id="txtPhone"
                                                                        className="txtbox form-control"
                                                                        onChange={(e) => {
                                                                            setLandingFormData({
                                                                                ...landingFormData, phone: e.target.value.replace(/\D/g, "")
                                                                            })
                                                                            checkTheLandlineAndPhoneNumber(e.target.value.replace(/\D/g, ""), landingFormData.dialCode)
                                                                        }} 
                                                                        placeholder="Telefon"
                                                                        value={landingFormData.phone}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='validationError'>
                                                            <p className='errorMsg'>{landingFormData?.phone?.length == "" ? phoneWarn : dialCodeWarn ? dialCodeWarn : ""}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 email_col">
                                                            <input
                                                                type="text"
                                                                placeholder="E-Posta Adresiniz"
                                                                className="form-control"
                                                                onKeyUp={() => simpleValidator?.current?.showMessageFor("email")}
                                                                onChange={(e) => setLandingFormData({
                                                                    ...landingFormData,
                                                                    email: e.target.value
                                                                })}
                                                                value={landingFormData.email}
                                                            />
                                                            <div className='validationError'>
                                                            <p className='errorMsg'>{landingFormData?.email?.length == "" || landingFormData.email.trim() === "" ? emailWarn : ""}</p>
                                                                <p className='errorMsg'>{simpleValidator?.current?.message('email', landingFormData?.email, 'email') ? "Lütfen Geçerli Bir E-Mail Adresi Yazın" : ""}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="terms_coldition">
                                                                        <input type="checkbox"
                                                                            onClick={() => handleChangeTerms(!landingFormData.terms)}
                                                                            value={landingFormData.terms}
                                                                            ref={checkboxTermsRef} />
                                                                        <a href="https://www.cubedots.com/terms_and_conditions" target="_blank">
                                                                            <h6>Aydınlatma Metni'ni okudum, anladım.</h6>
                                                                        </a>
                                                                        <div className='validationError'>
                                                                            <p className='errorMsg'>{ landingFormData.terms === false ? termsWarn : ""}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className="contact_box">
                                                                        <div className="phone_sms">
                                                                            <input type="checkbox" onClick={() => handleChangePhoneCheck(!landingFormData.PhoneCallCheck)}
                                                                                value={landingFormData.PhoneCallCheck}
                                                                                ref={checkboxPhoneRef} />
                                                                            <h6>Telefon Araması</h6>
                                                                        </div>
                                                                        <div className="phone_sms">
                                                                            <input type="checkbox" onClick={() => handleChangeSmsCheck(!landingFormData.smsCheck)}
                                                                                value={landingFormData.smsCheck}
                                                                                ref={checkboxSmsRef} />
                                                                            <h6>SMS Gönderimi/Whatsapp</h6>
                                                                        </div>
                                                                        <div className="phone_sms">
                                                                            <input type="checkbox" onClick={() => handleChangeEmailCheck(!landingFormData.emailCheck)}
                                                                                value={landingFormData.emailCheck}
                                                                                ref={checkboxEmailRef} />
                                                                            <h6>E-posta Gönderimi</h6>
                                                                        </div>
                                                                    </div>
                                                                    <p className="check_box_warn">{checkedCheckboxes.length < 1 ? dialsWarn : ""}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 text-center">
                                                            <Button loading={btnLoading} className="submit_btn" id="btnSubmit" onClick={onSubmit}>Gönder</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <a href="https://goo.gl/maps/pr4oVXFf2yNED1NZ8" target="_blank" rel="noreferrer"><button className="location_btn">
                                        Konum
                                    </button></a> */}
                                </div>
                            </div>
                        </div>
                        <div className="carousel_slider d-block d-md-none">
                            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                                        className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                                        aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                                        aria-label="Slide 3"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img className="img-fluid" src="/assets/images/valory/1.png" alt="konaklari" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/valory/2.png" alt="konaklari" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/valory/3.png" alt="konaklari" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <ValoryDetail />
                    <ValoryFloorplanDetail />
                    {/* <FooterCarousel /> */}
                    <FooterFormSection />
                    <div className="alya_logo">
                        <img src="/assets/images/valory/footer_logo.png" className="img=fluid" style={{ marginRight: "20px" }} />
                        <img src="/assets/images/valory/cubedots_logo.png" className="img=fluid" style={{ marginLeft: "20px" }} />
                    </div>
                </section>
            </div >
        </>
    )
}
export default Valory;