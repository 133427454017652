import React from "react";
import BlockDetail from "./BlockDetail";

const AlyakonutlariDetail = () => {
    return (
        <article className="main_content_section" id="02">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-9">
                        <div className="content_box">
                            <div className="row">
                                <div className="col-md-6 order-sm-1 order-2">
                                    <div className="ideal_content">
                                        <small className="small_text valory_heading" style={{ color: "#999999" }}>Valory Güneşlİ</small>
                                        <h1 style={{color:"#467B7C"}}>
                                            Valory Güneşli  ile Hayalinizdeki Eve Kavuşun
                                        </h1>
                                        <p>Valory Güneşli , sakinlerine modern ve kompakt 1+1 dairelerden geniş ve lüks 4+1 dairelere kadar çeşitli daire tipleri sunar. Modern dizayn düşüncemiz ve kaliteli iş bitiriciliğimiz sayesinde, her bir daire özenle tasarlanmıştır.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 order-sm-2 order-1">
                                    <div className="ideal_image">
                                        <img src="/assets/images/valory/4.png" className="img-fluid" alt="content_image" />
                                    </div>
                                </div>
                            </div>
                            <div className="family_friendly_main_section d-md-block d-none">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="family_friendly_box">
                                            {/* <span> */}
                                            <img src="/assets/images/valory/location.png" alt="ambiance" />
                                            {/* </span> */}
                                            <p className="family_friendly_box_title" style={{color:"#467B7C"}}>Yatırım Fırsatı</p>
                                            <p className="family_friendly_box_content">
                                            Valory Güneşli, İstanbul'un hızla büyüyen bölgelerinden birinde sizlere büyük bir yatırım fırsatı sunar. Lüks dairelerimiz, birinci sınıf olanaklarımız ve uygun konumumuz, yüksek yatırım getirisi sağlayarak yatırımcılar için birer çekim merkezi haline gelmektedir.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="family_friendly_box">
                                            {/* <span> */}
                                            <img src="/assets/images/valory/lifestyle.png" alt="location" />
                                            {/* </span> */}
                                            <p className="family_friendly_box_title" style={{color:"#467B7C"}}>Merkezi Konum</p>
                                            <p className="family_friendly_box_content">
                                                Basın Ekspres, iş, eğlence ve yatırım gibi birçok imkân sağlayan canlı ve dinamik bir bölgedir. Stratejik konumu ve gelişen altyapısı, hem yerel hem de yabancı yatırımcılara mükemmel bir fırsat sunuyor.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="family_friendly_box">
                                            {/* <span> */}
                                            <img src="/assets/images/valory/Oval.svg" alt="lifestyle" />
                                            {/* </span> */}
                                            <p className="family_friendly_box_title" style={{color:"#467B7C"}}>Birinci Sınıf Olanaklar</p>
                                            <p className="family_friendly_box_content">
                                                Modern fitness merkezimiz, spa ve çocuk oyun alanımız, sakinlerimiz için sağlıklı ve aktif bir yaşam tarzını teşvik ediyor.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <BlockDetail />
                            <div id="carouselFeature"
                                className="family_friendly_main_section d-block d-md-none carousel slide"
                                data-bs-ride="true">
                                {/*<ol className="carousel-indicators">*/}
                                {/*    <li data-target="#carouselExampleIndicator" data-slide-to="0" className="active"></li>*/}
                                {/*    <li data-target="#carouselExampleIndicator" data-slide-to="1"></li>*/}
                                {/*    <li data-target="#carouselExampleIndicator" data-slide-to="2"></li>*/}
                                {/*</ol>*/}
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselFeature" data-bs-slide-to="0"
                                        className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselFeature" data-bs-slide-to="1"
                                        aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselFeature" data-bs-slide-to="2"
                                        aria-label="Slide 3"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="family_friendly_box">
                                            {/* <span> */}
                                            <img src="/assets/images/valory/location.png" alt="ambiance" />
                                            {/* </span> */}
                                            <p className="family_friendly_box_title">Yatırım Fırsatı</p>
                                            <p className="family_friendly_box_content">
                                            Valory Güneşli, İstanbul'un hızla büyüyen bölgelerinden birinde sizlere büyük bir yatırım fırsatı sunar. Lüks dairelerimiz, birinci sınıf olanaklarımız ve uygun konumumuz, yüksek yatırım getirisi sağlayarak yatırımcılar için birer çekim merkezi haline gelmektedir.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="family_friendly_box">
                                            {/* <span> */}
                                            <img src="/assets/images/valory/lifestyle.png" alt="location" />
                                            {/* </span> */}
                                            <p className="family_friendly_box_title">Merkezi Konum</p>
                                            <p className="family_friendly_box_content">
                                                Basın Ekspres, iş, eğlence ve yatırım gibi birçok imkân sağlayan canlı ve dinamik bir bölgedir. Stratejik konumu ve gelişen altyapısı, hem yerel hem de yabancı yatırımcılara mükemmel bir fırsat sunuyor.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="family_friendly_box">
                                            {/* <span> */}
                                            <img src="/assets/images/valory/Oval.svg" alt="lifestyle" />
                                            {/* </span> */}
                                            <p className="family_friendly_box_title">Birinci Sınıf Olanaklar</p>
                                            <p className="family_friendly_box_content">
                                                Modern fitness merkezimiz, spa ve çocuk oyun alanımız, sakinlerimiz için sağlıklı ve aktif bir yaşam tarzını teşvik ediyor.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    )
}
export default AlyakonutlariDetail